.App {
  height: 100vh;
  background-color: hsl(240, 5%, 5%);
  overflow: scroll;
  text-align: center;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.sub-text {
  font-size: 30px;
  color: white;
}

.instructions-text {
	font-size: 25px;
	color: #eee;
	max-width: 32rem;
	margin: 1.2rem auto 0;
	line-height: 32px;
}

.instructions-text a,
.instructions-text a:visited  {
	color: #7cf;
	text-decoration: none;
}

.instructions-text a:hover {
	color: #8df;
	text-decoration: underline;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.submit-gif-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-left: 10px;
}

.footer-container {
  height: 30px;
  width: 100%;
  padding-bottom: 5px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.connected-container {
	padding: 0 10%;
}

.gif-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); */
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  justify-items: center;
  margin: 0;
  padding: 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-self: center;
  align-self: center;
}

.gif-item img {
  width: 100%;
  max-width: 500px;
  height: 300px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid #6a686f;
}

.gif-item .gif-user {
	padding: 15px 2px 5px;
	display: block;
	font-size: 13px;
	color: #ddd;
}

.connected-container input[type='text'] {
  display: inline-block;
  color: white;
  padding: 5px 10px;
  width: 50%;
  height: 40px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: hsl(240, 5%, 20%);
  border: 1px solid hsl(240, 5%, 25%);;
  border-radius: 5px;
  margin: 50px auto;
}

.connected-container button {
  height: 40px;
}
